<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <touchpoint />
   </Layout>
</template>
 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import touchpoint from "../campaign/touchpoint.vue";

export default {
   data() {
      return {
         title: "Touchpoints",
         items: [
            {
               text: "Home",
               href: "/",
            },
            {
               text: "List",
            },
         ],
      };
   },
   components: {
      Layout,
      touchpoint,
      PageHeader,
   },
};
</script>
 