<template>
  <div
    :class="$route.name == 'view-single-campaign' ? 'mt-4' : ''"
    id="engagement"
  >
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center mb-4">
          <div class="col-md-6">
            <div class="form-inline navbar-search">
              <div class="input-group">
                <input
                  name="title"
                  class="form-control bg-light border-0 small"
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  v-model="filter"
                  v-on:keyup="search"
                />
                <div class="input-group-append">
                  <button class="btn btn-search" @click="searchFor">
                    <i class="fas fa-search fa-sm"> </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <template v-if="$route.name == 'view-single-campaign'">
              <div class="all-tabs">
                <div
                  :class="
                    this.activeTab == 4 ? ' tab-options mx-2 opt' : 'mx-2 opt'
                  "
                  @click.prevent="fetchData(4)"
                >
                  All
                </div>
                |
                <div
                  :class="
                    this.activeTab == 0 ? ' tab-options mx-2 opt' : 'mx-2 opt'
                  "
                  @click.prevent="fetchData(0)"
                >
                  Pending
                </div>
                |
                <div
                  :class="activeTab == 1 ? 'tab-options mx-2 opt' : 'mx-2 opt'"
                  @click.prevent="fetchData(1)"
                >
                  Completed
                </div>
                |
                <div
                  :class="activeTab == 2 ? 'tab-options mx-2 opt' : 'mx-2 opt'"
                  @click.prevent="fetchData(2)"
                >
                  In Progress
                </div>
                |
                <div
                  :class="activeTab == 3 ? 'tab-options mx-2 opt' : 'mx-2 opt'"
                  @click.prevent="fetchData(3)"
                >
                  Failed
                </div>
                <template v-if="$route.name == 'view-single-campaign'">
                  <div
                    class="d-flex align-items-center"
                    v-if="can('add-communication')"
                  >
                    |
                    <svg
                      class="svg-inline--fa fa-table mx-2"
                      height="16px"
                      width="16px"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fa"
                      data-icon="table"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                      ></path>
                    </svg>
                    <router-link :to="{ name: 'add-touchpoint' }" class="opt"
                      >Add Touchpoint
                    </router-link>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="$route.name == 'touchpoint-list'">
              <div class="all-tabs">
                <div
                  :class="
                    this.activeTab == 4 ? ' tab-options mx-2 opt' : 'mx-2 opt'
                  "
                  @click.prevent="fetchTouchpointData(4)"
                >
                  All
                </div>
                |
                <div
                  :class="
                    this.activeTab == 0 ? ' tab-options mx-2 opt' : 'mx-2 opt'
                  "
                  @click.prevent="fetchTouchpointData(0)"
                >
                  Pending
                </div>
                |
                <div
                  :class="activeTab == 1 ? 'tab-options mx-2 opt' : 'mx-2 opt'"
                  @click.prevent="fetchTouchpointData(1)"
                >
                  Completed
                </div>
                |
                <div
                  :class="activeTab == 2 ? 'tab-options mx-2 opt' : 'mx-2 opt'"
                  @click.prevent="fetchTouchpointData(2)"
                >
                  In Progress
                </div>
                |
                <div
                  :class="activeTab == 3 ? 'tab-options mx-2 opt' : 'mx-2 opt'"
                  @click.prevent="fetchTouchpointData(3)"
                >
                  Failed
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="my-4 d-flex justify-content-start align-items-center">
          <template>
            <div class="d-flex align-items-center mr-4">
              <label class="mb-0 mr-3">Channel Type : </label>
              <multiselect
                id="input-multi"
                v-model="channelType"
                @input="getCreatedByUser"
                :options="createdByArr"
                :multiple="false"
                track-by="name"
                placeholder="Type to search"
                label="label"
                style="width: 300px"
              >
                <span slot="noOptions">Type here to search</span>
              </multiselect>

              <span class="reset-input">
                <i
                  class="ri ri-close-fill"
                  @click="resetChannelType"
                  style="cursor: pointer"
                ></i>
              </span>
            </div>
          </template>
          <div class="ml-3 d-flex align-items-center">
            <label class="mb-0 mr-3">Created By : </label>
            <multiselect
              id="input-multi"
              v-model="username"
              @input="getCreatedByUser"
              :options="getAdminUser"
              :multiple="false"
              track-by="id"
              placeholder="Type to search"
              label="username"
              style="width: 300px"
            >
              <span slot="noOptions">Type here to search</span>
            </multiselect>
            <span class="reset-input">
              <i
                class="ri ri-close-fill"
                @click="resetInput"
                style="cursor: pointer"
              ></i>
            </span>
          </div>
        </div>
        <div class="table-responsive mb-0">
          <template v-if="showData">
            <b-table
              striped
              bordered
              :items="tableData.data"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :per-page="0"
              :current-page="currentPage"
              :sort-desc.sync="sortDesc"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              responsive="sm"
              class="table-bordered table-hover"
              :key="key"
              show-empty
            >
              <template v-slot:cell(dasboard)="row">
                <router-link
                  :to="{
                    name: 'dashboard-touchpoint',
                    params: { id: row.item.id },
                    query: { campaign_id: $route.params.id },
                  }"
                >
                  <b-button
                    class="btn btn-sm btn-primary"
                    variant="primary"
                    dark
                    text
                  >
                    New Dasboard
                  </b-button>
                </router-link>
              </template>
              <template v-slot:empty>
                <div
                  style="
                    height: 200px;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                  "
                  class="d-flex justify-content-center align-items-center"
                >
                  <div style="display: grid">
                    <img src="../../../../public/noData.svg" alt="" />
                    <span style="font-size: 20px; font-weight: 700"
                      >No Content Available</span
                    >
                  </div>
                </div>
              </template>
              <template v-slot:cell(target_datafilter)="row">
                <template v-if="row.item.target_datafilter == 'speciality'">
                  <template
                    v-if="
                      row.item.specialities != null &&
                      row.item.specialities != ''
                    "
                  >
                    <template v-if="row.item.specialities.length > 2">
                      <div class="mc_data_filter">
                        <template v-for="f in row.item.specialities">
                          <div
                            class="badge spec_badge mr-1 mb-1"
                            :key="f.index"
                          >
                            {{ f }}
                          </div>
                        </template>
                      </div>
                    </template>
                    <template
                      v-else-if="
                        row.item.specialities.length < 2 ||
                        row.item.specialities.length == 2
                      "
                    >
                      <template v-for="f in row.item.specialities">
                        <div class="badge spec_badge mr-1 mb-1" :key="f.index">
                          {{ f }}
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div class="badge data_filter_badge mr-1 mb-1">
                    {{ row.item.target_datafilter }}
                  </div>
                </template>
              </template>
              <template v-slot:cell(engagement_name)="row">
                <template v-if="can('touchpoint-analytics')">
                  <router-link
                    :to="{
                      name: 'view-single-touchpoint',
                      query: { touchpoint_id: row.item.id },
                      params: { id: $route.params.id },
                    }"
                    class="c_touchpt_name"
                  >
                    <span
                      style="
                        word-break: break-all;
                        border-bottom: 1px solid #5664d2;
                      "
                      >{{ row.item.engagement_name }}</span
                    >
                  </router-link>
                </template>
                <template v-else>
                  <span class="c_touchpt_name">{{
                    row.item.engagement_name
                  }}</span>
                </template>
              </template>
              <template v-slot:cell(created_by)="row">
                <span class="badge badge-danger">{{
                  row.item.created_by
                }}</span>
              </template>
              <template v-slot:cell(notification_type)="row">
                <div class="text-center">
                  <img
                    :src="
                      row.item.notification_type == 'whatsapp'
                        ? '/campaign/whatsapp.svg'
                        : '/campaign/notifications.svg'
                    "
                    alt=""
                  />
                </div>
              </template>
              <template v-slot:cell(created_from)="row">
                <template v-if="row.item.created_from == 'partner'">
                  <span class="badge badge-primary">Partner Panel</span>
                </template>
                <template v-else>
                  <span class="badge badge-success">Medisage Panel</span>
                </template>
              </template>
              <template v-slot:cell(is_processed)="row">
                <div v-if="row.item.is_processed == 1">
                  <span class="badge badge-success">Completed</span>
                </div>
                <div v-else-if="row.item.is_processed == 0">
                  <span class="badge badge-info">Pending</span>
                </div>
                <div v-if="row.item.is_processed == 2">
                  <span class="badge badge-warning">In Progress</span>
                </div>
                <div v-if="row.item.is_processed == 3">
                  <span class="badge badge-danger">Failed</span>
                </div>
              </template>
              <template v-can="'edit-communication'" v-slot:cell(edit)="row">
                <div
                  v-if="
                    row.item.is_processed == 1 ||
                    row.item.is_processed == 2 ||
                    row.item.created_from == 'partner'
                  "
                >
                  <i class="fa fa-edit is_processed_edit"></i>
                </div>
                <router-link
                  :to="{
                    name: 'edit-touchpoint',
                    params: { id: row.item.id },
                    query: { campaign_id: $route.params.id },
                  }"
                  v-else
                >
                  <i class="fa fa-edit edit-i"></i>
                </router-link>
              </template>
              <template v-slot:cell(action_type)="row">
                <template
                  v-if="
                    row.item.action_type != null && row.item.action_type != ''
                  "
                >
                  <div class="text-center">
                    <span
                      style="text-transform: capitalize"
                      class="badge text-white"
                      :class="
                        row.item.action_type == 'video'
                          ? 'badge-secondary'
                          : row.item.action_type == 'case'
                          ? 'badge-primary'
                          : row.item.action_type == 'newsarticle'
                          ? 'badge-info'
                          : row.item.action_type == 'newsletter'
                          ? 'badge-danger'
                          : row.item.action_type == 'live_event'
                          ? 'badge-warning'
                          : row.item.action_type == 'custom'
                          ? 'badge-success'
                          : ''
                      "
                      :style="{ background: row.item.action_type === 'post' ? '#f1734f' : '' }"
                      >{{ row.item.action_type.replace(/_/, " ") }}</span
                    >
                  </div>
                </template>
              </template>
              <template
                v-slot:cell(delete)="row"
                v-can="'delete-communication'"
              >
                <div @click.prevent="deleteAppNotification(row.item.id)">
                  <i
                    class="mdi mdi-trash-can"
                    :class="
                      row.item.is_processed == 1 ||
                      row.item.is_processed == 2 ||
                      row.item.is_processed == 3 ||
                      row.item.created_from == 'partner'
                        ? 'is_processed_delete'
                        : 'delete-i'
                    "
                  ></i>
                </div>
              </template>
            </b-table>
          </template>
          <template v-if="showData">
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MixinRequest from "../../../mixins/request";
import touchpointMixin from "../../../mixins/ModuleJs/touchpoint";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      title: "Touchpoints",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
      action_type_color: "#0EA5E9",
    };
  },
  components: {
    Multiselect,
  },
  mixins: [MixinRequest, touchpointMixin],
};
</script>
